export const featureConfigConstants = {
  DISABLE_ASSIGNMENTS: "disable_assignments",
  DISABLE_TERM_EXAMS: "disable_term_exams",
  DISABLE_BUILD_TEST: "disable_build_test",
  DISABLE_WARM_UP_DELIVERY: "disable_warm_up_delivery",
  DISABLE_LEARN: "disable_learn",
  DISABLE_LIBRARY: "disable_library",
  DISABLE_MONTHLY_SCHEDULE: "disable_monthly_schedule",
  DISABLE_ANNOUNCEMENTS: "disable_announcements",
  DISABLE_ACTIVITIES: "disable_activities",
  DISABLE_HIERARCHIES: "disable_hierarchies",
  DISABLE_MISCELLANEOUS: "disable_miscellaneous",
  DISABLE_GAMES : "disable_games",
  DISABLE_CONFIGUE : "disable_configue",
  DISABLE_LEADS_GENERATION : "disable_leads_generation",
};

export const narayanaDummyFeatureConfig = {
  status: 200,
  data: {
    status: "success",
    data: {
      // [featureConfigConstants.DISABLE_ASSIGNMENTS]: true,
      // [featureConfigConstants.DISABLE_TERM_EXAMS]: true,
      // [featureConfigConstants.DISABLE_BUILD_TEST]: true,
      // [featureConfigConstants.DISABLE_WARM_UP_DELIVERY]: true,
      // [featureConfigConstants.DISABLE_LEARN]: true,
      // [featureConfigConstants.DISABLE_LIBRARY]: true,
      // [featureConfigConstants.DISABLE_MONTHLY_SCHEDULE]: true,
      // [featureConfigConstants.DISABLE_ANNOUNCEMENTS]: true,
      // [featureConfigConstants.DISABLE_ACTIVITIES]: true,
      // [featureConfigConstants.DISABLE_HIERARCHIES]: true,
      // [featureConfigConstants.DISABLE_MISCELLANEOUS]: true,
    },
  },
};

export const externalDummyFeatureConfig = {
  status: 200,
  data: {
    status: "success",
    data: {
      [featureConfigConstants.DISABLE_ASSIGNMENTS]: true,
      [featureConfigConstants.DISABLE_TERM_EXAMS]: true,
      [featureConfigConstants.DISABLE_BUILD_TEST]: true,
      [featureConfigConstants.DISABLE_WARM_UP_DELIVERY]: true,
      [featureConfigConstants.DISABLE_LEARN]: true,
      [featureConfigConstants.DISABLE_LIBRARY]: true,
      [featureConfigConstants.DISABLE_MONTHLY_SCHEDULE]: true,
      [featureConfigConstants.DISABLE_ANNOUNCEMENTS]: true,
      [featureConfigConstants.DISABLE_ACTIVITIES]: true,
      // [featureConfigConstants.DISABLE_HIERARCHIES]: true,
      [featureConfigConstants.DISABLE_MISCELLANEOUS]: true,
    },
  },
};

export const externalUserIdPasswords = () => {
  return {
    e111300: {
      username: "e111300",
      password: "test@123",
    },
  };
};
