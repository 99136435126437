import { Route, Switch } from "react-router-dom"
import { ParseLeadsGeneration } from "./routes/parse"
import { UploadLeadsGeneration } from "./routes/upload"
import { SubmitCsvLeadsGeneration } from "./routes/upload/submit-csv"
import { csvLeadsRoutes } from "./routes"
import { checkFeatureAccess } from "utils/role-restriction/helper";
import { useAppSelector } from "hooks/redux"

export const Leadsgeneration=({ match }: { match: any })=>{
    const { profileData } = useAppSelector((state) => state.gmailLoginReducer);
    const { role: userRoleStr } = profileData;
    const userRole = userRoleStr?.split(",");
    
    return(
        <>
        <Switch>
        {csvLeadsRoutes.map((route, i) => {
          return (
            checkFeatureAccess(userRole, route?.user) && (
              <Route
                key={i}
                exact
                path={`${match.url}${route.path}`}
                component={route.component}
              />
            )
          );
        })}
           {/* <Route exact path={`${match.url}/upload`} component={UploadLeadsGeneration}/> 
           <Route exact path={`${match.url}/submit_csv`} component={SubmitCsvLeadsGeneration}/> 
           <Route exact path={`${match.url}/parse`} component={ParseLeadsGeneration}/>  */}
        </Switch>
        </>
    )
}