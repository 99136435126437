export function formatDateTime(isoString) {
    if (!isoString || typeof isoString !== 'string') {
        return 'Invalid date';
    }

    const date = new Date(isoString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }

    date.setMinutes(date.getMinutes() + 330); 

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    // Extract time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format date and time
    //return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
}

export function truncateText(text, maxLength = 20) {
    if (typeof text !== 'string') {
        return ''; // Return an empty string if input is not a valid string
    }

    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}