import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { formatDateTime, truncateText } from "pages/leads-generation/helpers";
import leadsGenerateService from "pages/leads-generation/service";
import { isEmpty } from "utils/helpers";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
export const TableRowCollapse = ({ row }) => {
  const [open, setOpen] = useState(false);
  const dispatch=useAppDispatch()
  const {
    total_records_processed,
    total_admission_numbers_generated,
    admission_numbers_csv_url,
  } = row;

  const handleDownloadAdmissionNumber = async (id) => {
    try {
      const res:any = await leadsGenerateService.getOfflineDownloadCsv({
        offline_upload_id: id,
      });
      if (res?.data?.status=="Success" && !isEmpty(res?.data?.data?.admission_numbers_csv_url) ) {
        // const link = document.createElement("a");
        // link.href = res?.data?.data?.admission_numbers_csv_url;
        // link.setAttribute("download", `admission_numbers_${id}.csv`); // Suggests a filename (optional)
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const fileUrl = res.data.data.admission_numbers_csv_url;

        // Fetch the file as a Blob
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        // Create a temporary URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);
  
        // Create an anchor element
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `admission_numbers_${id}.csv`); // Set custom filename
        document.body.appendChild(link);
        link.click();
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }else{
        dispatch(snackbarRequest({message:res?.data?.message, type:'error'}))
      }
    } catch (error:any) {
      dispatch(snackbarRequest({message:error?.data?.message, type:'error'}))
    }
  };

  const isDisabled = disableAdmissionNumber(
    total_records_processed,
    total_admission_numbers_generated,
    admission_numbers_csv_url
  );

  return (
    <TableBody sx={{}}>
      <TableRow
        key={row?.id}
        sx={{
          "& td": {
            textAlign: "center",
          },
        }}
      >
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              component="p" // Ensures the element is a <p> tag
            >
              Uploaded Csv
            </Typography>
            <a
              href={row?.upload_csv_public_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileDownloadIcon />
            </a>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography component="p">Processed csv</Typography>
            <a
              href={row?.processed_csv_public_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileDownloadIcon />
            </a>
          </Box>
        </TableCell>
        <TableCell>{row?.total_records_processed}</TableCell>
        <TableCell>
          {row?.total_admission_numbers_generated
            ? row?.total_admission_numbers_generated
            : "-"}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                backgroundColor: row?.status === "processed" ? "green" : "red",
                fontWeight: "bold",
                color: "white",
                borderRadius: "10px",
                textAlign: "center",
                fontSize: "15px",
                width: "100px",
              }}
            >
              {row?.status}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Tooltip
            title={row?.error ? row?.error : "___"}
            placement="top"
            arrow
          >
            <Typography>
              {row?.error ? truncateText(row?.error) : "___"}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{formatDateTime(row?.created_at)}</TableCell>
        <TableCell>{formatDateTime(row?.updated_at)}</TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip title={isDisabled ? "no download" : "download"} placement="top" arrow>
              <Button
                onClick={() => {
                  handleDownloadAdmissionNumber(row?.id);
                }}
                disabled={isDisabled}
              >
                <FileDownloadIcon
                // sx={{
                //   color: isDisabled ? "gray" : "blue",
                //   pointerEvents: isDisabled ? "none" : "auto",
                //   opacity: isDisabled ? 0.5 : 1,
                // }}
                />
              </Button>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const disableAdmissionNumber = (
  total_records_processed,
  total_admission_numbers_generated,
  admission_numbers_csv_url
) => {
  if (
    total_records_processed === total_admission_numbers_generated
  ) {
    return false;
  } else {
    return true;
  }
};
