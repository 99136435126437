import { csvLeadsUser } from "utils/role-restriction/helper";
import { ParseLeadsGeneration } from "./routes/parse";
import { UploadLeadsGeneration } from "./routes/upload";
import { SubmitCsvLeadsGeneration } from "./routes/upload/submit-csv";


export const csvLeadsRoutes = [
    {
      exact: true,
      path: "/upload",
      component: UploadLeadsGeneration,
      user: [
        ...csvLeadsUser.CSV_LEADS_READ,...csvLeadsUser.CSV_LEADS_READ_WRITE
      ],
    },
    {
      exact: true,
      path: "/submit_csv",
      component: SubmitCsvLeadsGeneration,
      user: [
        ...csvLeadsUser.CSV_LEADS_READ_WRITE
      ],
    },
    {
      exact: true,
      path: "/parse",
      component: ParseLeadsGeneration,
      user: [
      ],
    },
]