import ScheduleIcon from "@mui/icons-material/Schedule";
import Assignment from "@mui/icons-material/AssignmentOutlined";
import Library from "@mui/icons-material/LocalLibraryOutlined";
import Announcement from "@mui/icons-material/AnnouncementOutlined";
import TermExams from "@mui/icons-material/QuizOutlined";
import LearnIcon from "@mui/icons-material/LibraryBooksOutlined";
import MiscellaneousIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { createTheme } from "@mui/material/styles";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import WarmUpLogo from "assets/Warm-up icon.svg";
import { featureConfigConstants } from "app-component/helpers";
import GamepadIcon from "@mui/icons-material/Gamepad";
// import HierarchiesMapIcon from 'assets/Hierarchies.svg'
import AccountTree from "@mui/icons-material/AccountTree";
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import { csvLeadsUser, plateformConfigueUser } from "utils/role-restriction/helper";
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
export const dummyFeatureConfig = {
  disable_assignments: true,
};

export const drawerWidth = 270;

export const features = [
  {
    isDisable: false,
    name: "Assignments",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_ASSIGNMENTS,
    icon: Assignment,
    children: [
      { name: "Create", type: "item", link: "/assignments/create" },
      { name: "List", type: "item", link: "/assignments/list" },
      {
        name: "List Deliveries",
        type: "item",
        link: "/assignments/delivery-list",
      },
    ],
  },
  {
    isDisable: false,
    name: "Term Exams",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_TERM_EXAMS,
    link: "/term-exams",
    icon: PendingActionsOutlinedIcon,
    children: [
      { name: "Create", type: "item", link: "/term-exams/create" },
      { name: "List", type: "item", link: "/term-exams/list" },
      {
        name: "List Deliveries",
        type: "item",
        link: "/term-exams/delivery-list",
      },
      {
        name: "QP Create PDF",
        type: "item",
        link: "/term-exams/generate-paper",
      },
      {
        name: "Saved Papers",
        type: "item",
        link: "/term-exams/saved-papers",
      },
    ],
  },

  {
    isDisable: false,
    name: "Build Test",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_BUILD_TEST,
    link: "/build-test",
    icon: ContentPasteOutlinedIcon,
    children: [
      {
        name: "Template",
        type: "item",
        link: "/build-test/template-list",
      },
      {
        name: "Question Group",
        type: "item",
        link: "/build-test/question-group-list",
      },
      { name: "Schedule Tests", type: "item", link: "/build-test/tests" },
      { name: "Delivery", type: "item", link: "/build-test/delivery" },
      { name: "Create With Excel", type: "item", link: "/build-test/create-with-excel" },
    ],
  },

  {
    name: "Warm-Up Delivery",
    type: "item",
    featureConfigKey: featureConfigConstants.DISABLE_WARM_UP_DELIVERY,
    link: "/warm-up-delivery",
    icon: WarmUpLogo,
    imageType: "svg",
    children: [],
  },

  {
    name: "Learn",
    type: "collapse",
    link: "/learn",
    featureConfigKey: featureConfigConstants.DISABLE_LEARN,
    icon: LearnIcon,
    children: [
      {
        name: "Create",
        type: "item",
        link: "/learn/create",
      },
      {
        name: "List",
        type: "item",
        link: "/learn/list",
      },
      { name: "Lock Topics", type: "item", link: "/learn/locktopics" },
      { name: "Disable Topics", type: "item", link: "/learn/disabletopics" },
    ],
  },

  {
    name: "Library",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_LIBRARY,
    icon: Library,
    children: [{ name: "List", type: "item", link: "/library" }],
  },
  {
    name: "Monthly Schedule",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_MONTHLY_SCHEDULE,
    icon: ScheduleIcon,
    children: [
      { name: "Create", type: "item", link: "/monthly-schedule/create" },
      {
        name: "List",
        type: "item",
        link: "/monthly-schedule/list",
      },
    ],
  },

  {
    name: "Announcements",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_ANNOUNCEMENTS,
    link: "/announcements",
    // featureConfigKey: featureConfigConstants.DISABLE_ANNOUNCEMENTS,
    icon: Announcement,
    children: [
      { name: "Create", type: "item", link: "/announcements/create" },
      { name: "List", type: "item", link: "/announcements/list" },
    ],
  },

  {
    name: "Activities",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_ACTIVITIES,
    link: "/activities",
    icon: SportsEsportsOutlinedIcon,
    children: [
      { name: "Create", type: "item", link: "/activities/create" },
      { name: "List", type: "item", link: "/activities/list" },
    ],
  },

  {
    name: "Hierarchies",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_HIERARCHIES,
    link: "/hierarchies",
    icon: AccountTree,
    children: [
      {
        name: "View Hierarchies",
        type: "item",
        link: "/hierarchies/view-hierarchies",
      },
      {
        name: "Map Hierarchy to Question",
        type: "item",
        link: "/hierarchies/map-hierarchy-to-question",
      },
    ],
  },

  {
    name: "Miscellaneous",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_MISCELLANEOUS,
    link: "/miscellaneous",
    icon: MiscellaneousIcon,
    children: [
      {
        name: "Instructions",
        type: "item",
        link: "/miscellaneous/instructions",
      },
      { name: "Misc Data", type: "item", link: "/miscellaneous/misc-data" },
      {
        name: "View Question",
        type: "item",
        link: "/miscellaneous/view-question",
      },
    ],
  },
  {
    name: "Games",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_GAMES,
    link: "/games",
    icon: GamepadIcon,
    children: [
      {
        name: "Vendor",
        type: "item",
        link: "/games/vendors",
      },
      {
        name: "Games",
        type: "item",
        link: "/games/games",
      },
      {
        name: "Tags",
        type: "item",
        link: "/games/tags",
      },
      {
        name: "Tag Items",
        type: "item",
        link: "/games/tag/items",
      },
      {
        name: "Game Tags",
        type: "item",
        link: "/games/gamestags",
      },
      {
        name: "Users",
        type: "item",
        link: "/games/users",
      },
      {
        name: "UserGames",
        type: "item",
        link: "/games/usergames",
      },
    ],
  },
  {
    name: "Platform Configue",
    type: "collapse",
    link: "/configue",
    featureConfigKey: featureConfigConstants.DISABLE_CONFIGUE,
    icon: DevicesOutlinedIcon,
    children: [
      {
        name: "Force Update",
        type: "item",
        link: "/configue/force_update",
        user:[...plateformConfigueUser?.FORCE_UPDATE_READ,...plateformConfigueUser?.FORCE_UPDATE_READ_WRITE]
      },
      {
        name: "Feature Config",
        type: "item",
        link: "/configue/feature_configue",
        user:[...plateformConfigueUser?.FEATURE_CONFIGUE_READ,...plateformConfigueUser?.FEATURE_CONFIGUE_READ_WRITE]
      },
      {
        name: "Student Course Lookup",
        type: "item",
        link: "/configue/student_course_lookup",
        user:[...plateformConfigueUser?.STD_COURSE_LU_READ,...plateformConfigueUser?.STD_COURSE_LU_READ_WRITE]
      },
      {
        name: "Student Program Lookup",
        type: "item",
        link: "/configue/student_program_lookup",
        user:[...plateformConfigueUser?.STD_PROGRAM_LU_READ,...plateformConfigueUser?.STD_PROGRAM_LU_READ_WRITE]
      },
      {
        name: "Create Course",
        type: "item",
        link: "/configue/create_course",
        user:[...plateformConfigueUser?.CREATE_COURSE_READ_WRITE]
      },
      {
        name: "Create Program",
        type: "item",
        link: "/configue/create_program",
        user:[...plateformConfigueUser?.CREATE_PROGRAM_READ_WRITE]
      },

    ],
  },
  {
    name: "Leads Generation",
    type: "collapse",
    featureConfigKey: featureConfigConstants.DISABLE_LEADS_GENERATION,
    link: "/leadsgenerations",
    icon: CloudSyncIcon,
    children: [
      {
        name: "Upload Leads",
        type: "item",
        link: "/leadsgenerations/upload",
        user:[...csvLeadsUser.CSV_LEADS_READ,...csvLeadsUser.CSV_LEADS_READ_WRITE
        ]
      }
    ],
  }
];

export const getEnableFeatures = (featureConfig) => {
  if (!featureConfig) return [];

  return features.filter((feature) => !featureConfig[feature.featureConfigKey]);
};

export const drawerTheme = createTheme({
  components: {
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
  },
  palette: {
    mode: "dark",
    primary: { main: "rgb(102, 157, 246)" },
    background: { paper: "rgb(15, 23, 42)" },
  },
});
